import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <div className='flex items-center justify-center h-screen'>
            <div className='text-center'>
                <p className='text-5xl mb-4'>404</p>
                <p className='text-2xl mb-8'>Page Not Found</p>
                <Link to="/" className='px-6 py-3 bg-[#007BFF] text-white rounded hover:bg-[#0056b3]'>
                    Go Home
                </Link>
            </div>
        </div>
    );
};

export default NotFound;