import React, { useContext } from 'react';
import ProLogo from './AptitudeLogo';
import NavButtons from '../reactcomponents/NavButtons';
import { CircleUserRound, PanelRightIcon, ChevronUp } from 'lucide-react';
import { useAuth0 } from "@auth0/auth0-react";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "@/@/components/ui/dropdown-menu";
import { SurveyContext } from "./SurveyContext";
import SubmissionsCount from './SubmissionsCount';
import { useNavigate } from 'react-router-dom';

const SideNavbar = () => {
    const { user, logout } = useAuth0();
    const { isCollapsed, setIsCollapsed } = useContext(SurveyContext);
    const navigate = useNavigate();

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <div className={`h-screen flex flex-col justify-between ${isCollapsed ? 'w-0' : 'w-48'} transition-width duration-300`}>
            <div>
                <div className=" flex ">
                    {!isCollapsed && <ProLogo />}
                    <button onClick={toggleCollapse} className={`absolute top-3 ${isCollapsed ? `left-[20px]` : `left-[235px]`}  `}>
                        <PanelRightIcon className='w-[20px] h-[20px]' />
                    </button>
                </div>
                {!isCollapsed && (
                    <>

                        <div className="flex mt-[25px] flex-col space-y-2 ">
                            <NavButtons />
                        </div>
                    </>
                )}
            </div>
            {!isCollapsed && (
                <div className="flex flex-col space-y-5 sticky bottom-[35px]">
                    <SubmissionsCount />
                    <DropdownMenu>
                        <DropdownMenuTrigger>
                            <div className="flex items-center p-3 w-[220px] rounded-lg hover:bg-gray-200">
                                {user.picture ? (
                                    <img
                                        src={user.picture}
                                        alt="User"
                                        className="h-[35px] w-[35px] rounded-full object-cover"
                                    />
                                ) : (
                                    <CircleUserRound className="h-[35px] w-[35px]" />
                                )}
                                <div className="flex flex-col items-start ml-2 overflow-hidden whitespace-nowrap ">
                                    <h3 className="text-sm font-semibold ">{user.nickname}</h3>
                                    <p className="text-sm text-gray-500 ">{user.email} </p>
                                </div>
                                <div>
                                    <ChevronUp className="h-[20px] w-[20px] ml-2" />
                                </div>
                            </div>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent className="w-56 ml-6">
                            <DropdownMenuLabel>My Account</DropdownMenuLabel>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem onClick={() => { navigate("/settings"); }} className="cursor-pointer">Settings</DropdownMenuItem>
                            <DropdownMenuItem onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })} className="cursor-pointer">Logout</DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
            )}
        </div>
    );
};

export default SideNavbar;