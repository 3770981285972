import React, { useContext } from 'react';
import { ArrowLeft, ChevronUp, CircleUserRound, CreditCard, PanelRightIcon } from 'lucide-react';
import { Button } from '../@/components/ui/button';
import { useAuth0 } from "@auth0/auth0-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/@/components/ui/dropdown-menu";
import { SurveyContext } from "./SurveyContext";
import { useNavigate } from 'react-router-dom';

const SettingsNavbar = () => {
  const { user, logout } = useAuth0();
  const { isCollapsed, setIsCollapsed } = useContext(SurveyContext);
  const navigate = useNavigate();

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleButtonClick = (path) => {
    navigate(path);
  };


  return (
    <div className={`h-screen flex flex-col justify-between ${isCollapsed ? 'w-0' : 'w-48'} transition-width duration-300`}>
      <div>
        <div className=" flex ">
          {!isCollapsed && <div className="hover:cursor-pointer" onClick={() => { handleButtonClick("/home"); }}>
            <button className="flex items-center text-gray-700 hover:text-gray-900">
              <ArrowLeft className="h-5 w-5 mr-2" />
              <span>Back</span>
            </button>
          </div>}
          <button onClick={toggleCollapse} className={`absolute top-3 ${isCollapsed ? `left-[20px]` : `left-[235px]`}  `}>
            <PanelRightIcon className='w-[20px] h-[20px]' />
          </button>
        </div>
        {!isCollapsed && (
          <>

            <div className="flex mt-[25px] flex-col space-y-5 ">
              <div>
                <div className='font-semibold mb-2'>Settings</div>
                <Button onClick={() => { handleButtonClick('/settings'); }} className="flex items-center justify-start p-2 bg-[#f4f4f5] text-black hover:bg-[#D9D9D9] w-full">
                  <CreditCard className="mr-2 h-[20px] w-[20px]" /><p className='text-md font-normal'>Plans and Billing</p>
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
      {!isCollapsed && (
        <div className="flex flex-col space-y-5 sticky bottom-[0px]">
          <DropdownMenu>
            <DropdownMenuTrigger>

              <div className="flex items-center p-3 w-[220px] rounded-lg hover:bg-gray-200">
                {user.picture ? (
                  <img
                    src={user.picture}
                    alt="User"
                    className="h-[35px] w-[35px] rounded-full object-cover"
                  />
                ) : (
                  <CircleUserRound className="h-[35px] w-[35px]" />
                )}
                <div className="flex flex-col items-start ml-2 overflow-hidden whitespace-nowrap ">
                  <h3 className="text-sm font-semibold ">{user.nickname}</h3>
                  <p className="text-sm text-gray-500 ">{user.email} </p>
                </div>
                <div>
                  <ChevronUp className="h-[20px] w-[20px] ml-2" />
                </div>
              </div>

            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56 ml-6">
              <DropdownMenuLabel>My Account</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuItem onClick={() => { navigate("/settings"); }} className="cursor-pointer">Settings</DropdownMenuItem>
              <DropdownMenuItem onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })} className="cursor-pointer">Logout</DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      )}
    </div>
  );
};

export default SettingsNavbar;