import React, { useState } from "react";
import { format } from 'date-fns';
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/@/components/ui/table";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger, DialogDescription } from "@/@/components/ui/dialog";
import { Input } from "@/@/components/ui/input";
import { Button } from "@/@/components/ui/button";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/@/components/ui/accordion";
import { BarChart2, FileType2, PlayIcon } from "lucide-react";

const SubmissionsTabContent = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedSubmission, setSelectedSubmission] = useState(null);

  const sampleSubmissions = [
    {
      submitted_at: "2023-01-01T12:00:00Z",
      firstName: "John",
      lastName: "Doe",
      phoneNumber: "123-456-7890",
      email: "john.doe@example.com",
      interview_summary: "The call summarizes a user's experience with the Nike Invincible Run 3 running shoes. The user initially praised the shoes for their exceptional softness, but later expressed that this softness hindered performance during speed workouts, making them feel slow and lacking bounce. They suggested that more responsive cushioning would be beneficial for faster-paced runs.",
      interview_recording: "https://example.com/recording",
      interview_transcript: `AI: Hey there! How are you doing today? User: I'm doing good. How about you? AI: I'm doing well, thank you for asking! I’m here today to talk about the Nike Invincible Run 3 running shoes. Since you’ve been using them, I’d love to hear about your experiences with them. To start off, what were your first impressions when you tried on the Nike Invincible Run 3? User: Well, I was surprised by how soft they were. I've tried a lot of different running shoes, but I think the Nike Invisible Run 3 is by far the softest ones that tried, so that was a very pleasant surprise. AI: That's great to hear that the softness made a positive impression on you! Comfort is definitely key when it comes to running shoes During your runs, how have you found that softness to translate into performance? Has it impacted your User: Well, I feel like the softness doesn't work as well, um, especially when I'm doing my speed workouts and I need, you know, faster tempo. Um, I feel like the softness actually kind of hinders those speed workouts and makes me feel slow and mushy. And so if there were a bit more bounce to the cushioning, I would really appreciate that. AI: Interesting, thank you for sharing that. So in terms of speed workouts, you feel that the softness of the Nike and Vince e Run 3 may not provide the support you need for faster tempos.`
    }
  ];

  const filteredSubmissions = sampleSubmissions.filter(submission => {
    const firstName = submission.firstName || '';
    const lastName = submission.lastName || '';
    const email = submission.email || '';
    const phoneNumber = submission.phoneNumber || '';
    const interviewSummary = submission.interview_summary || '';
    const interviewTranscript = submission.interview_transcript || '';
    const interviewRecording = submission.interview_recording || '';
    const query = searchQuery.toLowerCase();
    return (
      firstName.toLowerCase().includes(query) ||
      lastName.toLowerCase().includes(query) ||
      email.toLowerCase().includes(query) ||
      phoneNumber.toLowerCase().includes(query) ||
      interviewSummary.toLowerCase().includes(query) ||
      interviewTranscript.toLowerCase().includes(query) ||
      interviewRecording.toLowerCase().includes(query)
    );
  });

  const isValidUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  const formatTranscript = (transcript) => {
    const lines = transcript.split(/(?=AI:|User:)/);
    return lines.map(line => {
      const trimmedLine = line.trim();
      return {
        text: trimmedLine,
        speaker: trimmedLine.startsWith('AI:') ? 'AI' : 'User'
      };
    });
  };

  return (
    <div className="p-4">
      <div className="flex justify-end pb-2">
        <div className="flex mb-4">
          <Input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </div>
      {filteredSubmissions.length === 0 ? (
        <div className="flex flex-col items-center justify-center h-full py-10">
          <svg
            className="w-16 h-16 mb-4 text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 12h6m-6 4h6m2 4H7a2 2 0 01-2-2V6a2 2 0 012-2h7l5 5v11a2 2 0 01-2 2z"
            ></path>
          </svg>
          <h2 className="text-xl font-semibold text-gray-700">No Responses Found</h2>
          <p className="text-gray-500">Share your link to start receiving responses</p>
        </div>
      ) : (
        <Table>
          <TableCaption>Responses</TableCaption>
          <TableHeader>
            <TableRow>
              <TableHead>Submitted At</TableHead>
              <TableHead>First Name</TableHead>
              <TableHead>Last Name</TableHead>
              <TableHead>Phone Number</TableHead>
              <TableHead>Email</TableHead>
              <TableHead>Response</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {filteredSubmissions.map((submission, index) => (
              <Dialog key={index} open={selectedSubmission === index} onOpenChange={(isOpen) => setSelectedSubmission(isOpen ? index : null)}>
                <DialogTrigger asChild>
                  <TableRow className="cursor-pointer group" onClick={() => setSelectedSubmission(index)}>
                    <TableCell className="font-medium">
                      {format(new Date(submission.submitted_at), 'MMM d, h:mma')}
                    </TableCell>
                    <TableCell>{submission.firstName}</TableCell>
                    <TableCell>{submission.lastName}</TableCell>
                    <TableCell>{submission.phoneNumber}</TableCell>
                    <TableCell>{submission.email}</TableCell>
                    <TableCell className="text-right">
                      <Button className="opacity-0 group-hover:opacity-100 transition-opacity text-white bg-[#0C003F]  hover:bg-[#0c003fc8]">View Response →</Button>
                    </TableCell>
                  </TableRow>
                </DialogTrigger>
                <DialogContent className="max-w-[700px] h-[500px] ">
                  <DialogHeader>
                    <DialogTitle>Interview Response Details</DialogTitle>
                    <DialogDescription>
                      <Accordion type="single" collapsible className="w-full" defaultValue="item-1">
                        <AccordionItem value="item-1">
                          <AccordionTrigger>
                            <div className="flex">
                              <BarChart2 className="mr-2 mt-[-4px] text-black" />
                              <h2 className="text-black"> Summary</h2>
                            </div>
                          </AccordionTrigger>
                          <AccordionContent>
                            <div className="max-h-40 overflow-y-auto">
                              {submission?.interview_summary || 'No summary provided'}
                            </div>
                          </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="item-2">
                          <AccordionTrigger>
                            <div className="flex">
                              <PlayIcon className="mr-2 fill-black text-black mt-[-2px]" />
                              <h2 className="text-black"> Recording</h2>
                            </div>
                          </AccordionTrigger>
                          <AccordionContent>
                            {isValidUrl(submission?.interview_recording) ? (
                              <a className="underline text-blue-500" href={submission.interview_recording} target="_blank" rel="noopener noreferrer">
                                Recording URL
                              </a>
                            ) : (
                              'No recording provided'
                            )}
                          </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="item-3">
                          <AccordionTrigger>
                            <div className="flex">
                              <FileType2 className="mr-2 text-black mt-[-4px]" />
                              <h2 className="text-black"> Transcript</h2>
                            </div>
                          </AccordionTrigger>
                          <AccordionContent>
                            <div className="max-h-64 overflow-y-auto whitespace-pre-wrap">
                              {submission?.interview_transcript ? formatTranscript(submission.interview_transcript).map((line, index) => (
                                <div key={index} className="mb-2">
                                  <span className={line.speaker === 'AI' ? 'text-green-500' : 'text-blue-500'}>
                                    {line.speaker}:
                                  </span> {line.text.replace(/^(AI:|User:)/, '')}
                                </div>
                              )) : 'No transcript provided'}
                            </div>
                          </AccordionContent>
                        </AccordionItem>
                      </Accordion>
                    </DialogDescription>
                  </DialogHeader>
                </DialogContent>
              </Dialog>
            ))}
          </TableBody>
        </Table>
      )}
    </div>
  );
};

export default SubmissionsTabContent;