import React, { useState } from 'react';
import { Input } from '@/@/components/ui/input';
import { FlagIcon } from 'lucide-react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useParams } from "react-router-dom";
import { useEffect } from 'react';

const ContactForm = () => {
    const { id } = useParams();
    const [form, setForm] = useState(null);
    const [assistantId, setassistantId] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (!form) {
            const fetchForm = async () => {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-form/${id}`);
                    setForm(response.data.form);
                    setassistantId(response.data.form.assistant_id);
                } catch (error) {
                    console.error('Error fetching form:', error);
                }
            };
            fetchForm();
        }
    }, [form, id]);


    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        id: '',
    });

    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: ''
    });

    const [submitStatus, setSubmitStatus] = useState(null);

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value
        }));
    };

    const validate = () => {
        let valid = true;
        let errors = {};

        if (!formData.firstName.trim()) {
            errors.firstName = 'First name is required';
            valid = false;
        }

        if (!formData.lastName.trim()) {
            errors.lastName = 'Last name is required';
            valid = false;
        }

        // const phoneNumberPattern = /^\(\d{3}\) \d{3}-\d{4}$/;
        // if (!phoneNumberPattern.test(formData.phoneNumber)) {
        //     errors.phoneNumber = 'Phone number must be in the format (201) 555-0123';
        //     valid = false;
        // }

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(formData.email)) {
            errors.email = 'Email must be a valid email address';
            valid = false;
        }

        setErrors(errors);
        return valid;
    };

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isSubmitting) return;
        setIsSubmitting(true);

        const responseId = uuidv4();
        const formId = id;
        const options = {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${process.env.REACT_APP_VAPI_BEARER_KEY}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                serverUrl: `${process.env.REACT_APP_API_BASE_URL}/end-of-call/${responseId}`
            })
        };
        if (validate()) {
            try {
                await fetch(`https://api.vapi.ai/assistant/${assistantId}`, options);

                await axios.post(`${process.env.REACT_APP_API_BASE_URL}/submit-response/${id}`, {
                    id: responseId,
                    form_id: formId,
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    email: formData.email,
                    phoneNumber: formData.phoneNumber,
                    response: "dummy response"
                });
                setSubmitStatus('Form submitted successfully!');
            } catch (error) {
                setSubmitStatus('Error submitting form');
                console.error('Error:', error);
            } finally {
                setIsSubmitting(false);
                navigate(`/survey-intro/${id}`, { state: { form: form } });
            }
        } else {
            setIsSubmitting(false);
        }
    };


    return (
        <div className="mx-auto">
            <h1 className="text-[30px] font-bold mb-8 font-sans">Let’s get some quick contact information before we begin!</h1>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="block text-[18px] font-medium text-gray-700" htmlFor="firstName">First name</label>
                    <Input
                        className="p-2"
                        type="text"
                        id="firstName"
                        placeholder="Jane"
                        value={formData.firstName}
                        onChange={handleChange}
                    />
                    {errors.firstName && <p className="text-red-500 text-sm">{errors.firstName}</p>}
                </div>
                <div className="mb-4">
                    <label className="block text-[18px] font-medium text-gray-700" htmlFor="lastName">Last name</label>
                    <Input
                        type="text"
                        id="lastName"
                        placeholder="Smith"
                        value={formData.lastName}
                        onChange={handleChange}
                    />
                    {errors.lastName && <p className="text-red-500 text-sm">{errors.lastName}</p>}
                </div>
                <div className="mb-4">
                    <label className="block text-[18px] font-medium text-gray-700" htmlFor="phoneNumber">Phone number</label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                        <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                            <FlagIcon size={16} />
                        </span>
                        <Input
                            type="text"
                            id="phoneNumber"
                            placeholder="(201) 555-0123"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                        />
                    </div>
                    {errors.phoneNumber && <p className="text-red-500 text-sm">{errors.phoneNumber}</p>}
                </div>
                <div className="mb-4">
                    <label className="block text-[18px] font-medium text-gray-700" htmlFor="email">Email</label>
                    <Input
                        type="email"
                        id="email"
                        placeholder="name@example.com"
                        value={formData.email}
                        onChange={handleChange}
                    />
                    {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
                </div>
                <div className="flex justify-end">
                    <button
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-[#0C003F] hover:bg-[#0c003fc8] disabled:opacity-50 disabled:cursor-not-allowed"
                        type="submit"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? 'Submitting...' : 'Next →'}
                    </button>
                    {submitStatus && <p>{submitStatus}</p>}
                </div>
            </form>
        </div>
    );
};

export default ContactForm; 