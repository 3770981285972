import React from 'react';
import { createRoot } from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { SurveyProvider } from './reactcomponents/SurveyContext';

const root = createRoot(document.getElementById('root'));

const redirectUri = process.env.REACT_APP_AUTH0_REDIRECT_URI || "http://localhost:3000/home";

root.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    authorizationParams={{
      redirect_uri: redirectUri
    }}
  >
    <SurveyProvider>
      <App />
    </SurveyProvider>
  </Auth0Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export const isPublicKeyMissingError = ({ vapiError }) => {
  return !!vapiError && vapiError.error.statusCode === 403 && vapiError.error.error === "Forbidden";
};