import { Settings, PenLine } from "lucide-react";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid'; // Import uuid
import { Button } from "@/@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/@/components/ui/dialog";
import { useAuth0 } from "@auth0/auth0-react";

const NewInterview = () => {
  const [isDraft, setIsDraft] = useState(true);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false); // 
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false); // track  unsaved changes
  const [interview, setInterview] = useState({
    interviewInfo: '',
    interviewInstructions: '',
    interviewName: ''
  });
  const formatDateWithDashes = () => {
    const now = new Date();
    const day = now.getDate().toString().padStart(2, '0');
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = months[now.getMonth()];
    const year = now.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const [title, setTitle] = useState(() =>
    "New Interview " + formatDateWithDashes()
  );

  const { user } = useAuth0();
  const [assistantId, setAssistantId] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-forms/${user.sub}`);
        setInterview({
          interviewInfo: response.data.interviewInfo,
          interviewInstructions: response.data.interviewInstructions,
          interviewName: response.data.interviewName
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [user.sub]);


  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const enableEdit = () => {
    setIsEditingTitle(true);
  };

  const disableEdit = () => {
    setIsEditingTitle(false);
  };

  const navigate = useNavigate();
  const handleHomeClick = () => {
    navigate('/home');
  };
  const handlePreviewClick = () => {
    navigate('/contactform/surveyintro');
  };


  const handleInfoChange = (e) => {
    setInterview({
      ...interview,
      interviewInfo: e.target.value
    });
    setHasUnsavedChanges(true);
  };

  const handleInstructionsChange = (e) => {
    setInterview({
      ...interview,
      interviewInstructions: e.target.value
    });
    setHasUnsavedChanges(true);
  };

  const handleNameChange = (e) => {
    setInterview({
      ...interview,
      interviewName: e.target.value
    });
    setHasUnsavedChanges(true);
  };

  const interviewInstructions = interview.interviewInstructions;

  const vapiBearerKey = `${process.env.REACT_APP_VAPI_BEARER_KEY}`;

  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${vapiBearerKey}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      transcriber: {
        provider: "deepgram",
        model: "nova-2-general",
        language: "en",
        smartFormat: false,
        languageDetectionEnabled: true,
      },
      model: {
        provider: "openai",
        model: "gpt-4",
        temperature: 0.7,
        knowledgeBase: {
          "fileIds": [],
          "provider": "canonical"
        },
        messages: [
          {
            role: "system",
            content: `Interview Prompt: ${interviewInstructions}`
          }
        ]
      },
      voice: {
        provider: "11labs",
        voiceId: "cgSgspJ2msm6clMCkdW9",
        model: "eleven_multilingual_v2"
      },
      firstMessageMode: "assistant-speaks-first",
      backchannelingEnabled: true,
      modelOutputInMessagesEnabled: true,
      name: user.name,
      firstMessage: "Hey there! How are you doing today?",
      endCallMessage: "Thank you for your time and I appreciate you providing your feedback. Have a great day!",
    })
  };

  const saveInfo = async () => {
    setIsSubmitted(true);
    setHasUnsavedChanges(false);
    const interviewId = uuidv4(); // Generate a new UUID

    let assistantId;

    // Assistant API request
    try {
      const response = await fetch('https://api.vapi.ai/assistant', options);
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Network response was not ok: ${response.status} - ${errorText}`);
      }
      const data = await response.json();
      assistantId = data.id;
      setAssistantId(assistantId);
    } catch (error) {
      console.error('Error with the second request:', error);
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/create-form`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          assistant_id: assistantId,
          user_id: user.sub,
          title,
          id: interviewId,
          interviewInfo: interview.interviewInfo,
          interviewInstructions: interview.interviewInstructions,
          interviewName: interview.interviewName
        })
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      // Update state based on the response if needed
    } catch (error) {
      console.error('Error submitting form:', error);
    }

    navigate('/home');
  };




  // const handleSubmit = async (e) => {
  //     e.preventDefault();
  //     try {
  //         const response = await axios.post('http://localhost:5000/submit-form', formData);
  //         console.log('Server Response:', response.data);
  //     } catch (error) {
  //         console.error('Error submitting form:', error);
  //     }
  // };



  return (
    <div >
      <div className="flex justify-between">
        <nav className="text-sm text-gray-600 mb-4">
          <button onClick={handleHomeClick} className="hover:underline">My workspace</button> &gt; <span className="">{title}</span>
        </nav>
        <div className="flex justify-end mb-4 text-center items-center">
          {isDraft && <div className="bg-gray-200 text-gray-700 text-xs font-bold pt-1 text-center rounded-md p-1 mr-3">Draft</div>}
          {!isDraft && !hasUnsavedChanges && <div className="bg-gray-200 text-gray-700 text-xs font-bold pt-1 text-center rounded-md p-1 mr-3">Published</div>}
          {isSubmitted && hasUnsavedChanges && <div className="bg-blue-400 text-white text-xs font-bold pt-1 text-center rounded-md p-1 mr-3">Changes</div>}
          <button className="text-[#9e9e9b] hover:text-gray-800 flex items-center justify-center mr-4">
            <span className="scale-75">
              <Settings />
            </span>
          </button>
          {!isSubmitted ? <div className="mr-3 text-gray-500 text-sm font-medium">Preview</div> : <button onClick={handlePreviewClick} className="mr-3 text-blue-500 text-sm font-medium">Preview</button>}
          {interview.interviewName && interview.interviewInfo && interview.interviewInstructions && hasUnsavedChanges ?
            <Dialog>
              <DialogTrigger asChild>
                <button className="bg-blue-500 text-white py-1 px-2 text-xs font-medium rounded-md">Publish</button>
              </DialogTrigger>
              <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                  <DialogTitle>Confirmation</DialogTitle>
                  <DialogDescription>
                    Are you sure you want to publish this interview? Once published, the interview will be live.
                  </DialogDescription>
                </DialogHeader>
                <DialogFooter>
                  <Button className="bg-blue-500 hover:bg-blue-800" onClick={() => {
                    saveInfo();
                    setIsDraft(false);
                  }}
                  >Publish</Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
            :
            <div className=" text-gray-600 text-sm font-medium">Publish</div>
          }
        </div>
      </div>
      <h1 className="text-2xl font-bold mb-2 flex">
        {isEditingTitle ? (
          <input
            type="text"
            value={title || ""}
            onChange={handleTitleChange}
            onBlur={disableEdit}
            onKeyDown={(event) => event.key === 'Enter' && disableEdit()}
            className="text-2xl font-bold mb-2"
            autoFocus
          />
        ) : (
          <>
            {title}
            <button
              className="text-[#9e9e9b] hover:text-gray-800 flex items-center justify-center ml-2"
              onClick={enableEdit}
            >
              <div className="scale-75">
                <PenLine />
              </div>
            </button>
          </>
        )}
      </h1>
      <div className="mb-4">
        <div className="flex items-center">
          <h2 className="text-lg font-semibold">Interview Name</h2>
          <span className="text-red-500 font-bold text-xl ml-1">*</span>
        </div>
        <p className="text-gray-600 text-sm mb-2">Please provide a title for this interview (This is the title your participants will see).</p>
        <input
          className=" border rounded-lg p-2"
          value={interview.interviewName || ""}
          onChange={handleNameChange}
        ></input>
      </div>
      <div className="mb-4">
        <div className="flex items-center">
          <h2 className="text-lg font-semibold">Interview Information</h2>
          <span className="text-red-500 font-bold text-xl ml-1">*</span>
        </div>
        <p className="text-gray-600 text-sm mb-2">Please provide a title for this interview (This is the title your participants will see).</p>
        <textarea
          className="w-full h-32 border rounded-lg p-2"
          value={interview.interviewInfo || ""}
          onChange={handleInfoChange}
        ></textarea>
      </div>
      <div className="mb-4">
        <div className="flex items-center">
          <h2 className="text-lg font-semibold">Configure your AI Interviewer</h2>
          <span className="text-red-500 font-bold text-xl ml-1">*</span>
        </div>
        <p className="text-gray-600 text-sm mb-2">Please provide a prompt to configure your AI interviewer. This is where you will describe how the AI interviewer should behave and what questions it should ask during the interview.</p>
        <textarea
          className="w-full h-32 border rounded-lg p-2"
          value={interview.interviewInstructions || ""}
          onChange={handleInstructionsChange}
        ></textarea>
      </div>
      {/* <div className="mb-4">
        <h2 className="text-lg font-semibold">Knowledge Base</h2>
        <p className="text-gray-600 text-sm mb-2">Upload relevant files and documents that the AI interview can reference.</p>
        <div className="w-full h-32 border-dashed border-2 rounded-lg flex items-center justify-center text-gray-500">
          <UploadFile />
        </div>
      </div> */}
    </div >
  );
};

export default NewInterview;
