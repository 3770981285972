// import React from "react";

// const numBars = 10;

// const VolumeLevel = ({ volume }) => {
//     return (
//         <div style={{ padding: "20px" }}>
//             {/* <div style={{ color: "black", marginBottom: "8px" }}>
//                 <p>Volume Level:</p>
//             </div> */}
//             <div style={{ display: "flex", marginBottom: "10px" }}>
//                 {Array.from({ length: numBars }, (_, i) => (
//                     <div
//                         key={i}
//                         style={{
//                             width: "20px",
//                             height: "20px",
//                             margin: "2px",
//                             backgroundColor: i / numBars < volume ? "#3ef07c" : "black",
//                             borderRadius: "2px",
//                         }}
//                     />
//                 ))}
//             </div>
//             {/* <div style={{ color: "black" }}>{volume}</div> */}
//         </div>
//     );
// };

// export default VolumeLevel;

import React from "react";

const numBars = 10;

const VolumeLevel = ({ volume }) => {
    return (
        <div className="p-5">
            <div className="flex mb-2">
                {Array.from({ length: numBars }, (_, i) => (
                    <div
                        key={i}
                        className={`w-5 h-10 m-1 rounded-sm ${i / numBars < volume ? "bg-green-400 animate-pulse" : "bg-black"
                            }`}
                    />
                ))}
            </div>
        </div>
    );
};

export default VolumeLevel;