import React from 'react';

const ShareTabContent = ({ link, handleCopyClick }) => {
  return (
    <div className="mt mb-4">
      {/* <p className="text-green-500">Interview published successfully!</p> */}
      <div className="mt-2">
        <label className="block text-black text-sm font-bold mb-2" htmlFor="link">
          Copy this link:
        </label>
        <div className="flex items-center">
          <input
            className="w-full border rounded-lg p-2"
            type="text"
            value={link}
            readOnly
          />
          <button
            className="ml-2 bg-[#0C003F] text-sm text-white px-4 py-2 rounded-md hover:bg-[#0c003fc7]"
            onClick={handleCopyClick}
          >
            Copy
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShareTabContent;