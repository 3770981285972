import React from 'react';
import logo from "../logo.png";

const AptitudeLogo = () => {
    return (
        <div className="flex items-center ">
            <div><img src={logo} width={40} height={40} alt="Logo" /></div>
            <p className="text-2xl ml-2 font-bold text-[#0C003F]">Aptitude</p>
        </div>
    );
};

export default AptitudeLogo;