import React, { createContext, useState } from 'react';

export const SurveyContext = createContext();

export const SurveyProvider = ({ children }) => {
    const [state, setState] = useState({
        interviewInfo: '',
        interviewInstructions: '',
        interviewName: ''
    });
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [submissionsCount, setSubmissionsCount] = useState(0);
    const [link, setLink] = useState('');


    const makePostRequest = async (url, data) => {
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            const result = await response.json();
            setResponse(result);
        } catch (error) {
            setError(error.message);
        }

    };

    // useEffect(() => {
    //     fetch('https://api.yourdomain.com/your-endpoint', {
    //         method: 'GET', // or 'POST', 'PUT', etc.
    //         headers: {
    //             'Content-Type': 'application/json',
    //             // Any other headers you need to include
    //         }
    //     })
    //         .then(response => {
    //             if (!response.ok) {
    //                 throw new Error('Network response was not ok');
    //             }
    //             return response.json();
    //         })
    //         .then(data => {
    //             setData(data);
    //             setLoading(false);
    //         })
    //         .catch(error => {
    //             setError(error);
    //             setLoading(false);
    //         });
    // }, []); 

    return (
        <SurveyContext.Provider value={{ state, setState, response, error, makePostRequest, isCollapsed, setIsCollapsed, submissionsCount, setSubmissionsCount, link, setLink }}>
            {children}
        </SurveyContext.Provider>
    );
};