import React from 'react';
import { Home, MessageCircleQuestion } from 'lucide-react';
import { Button } from '../@/components/ui/button';

import { useNavigate } from 'react-router-dom';

const NavButtons = () => {
    const navigate = useNavigate();

    const handleButtonClick = (path) => {
        navigate(path); // Path to routes
    };

    return (
        <>
            <Button onClick={() => { handleButtonClick('/home'); }} className="flex items-center justify-start p-2 bg-[#f4f4f5] text-black hover:bg-[#D9D9D9] w-full">
                <Home className="mr-3 h-[20px] w-[20px]" /><p className='text-md font-normal'>Home</p>
            </Button>
            <Button onClick={() => { window.open('https://join.slack.com/t/aptitude/shared_invite/zt-2sa11c5iq-bTkiKvM8eTkmj2oiw7RBpg', '_blank'); }} className="flex items-center justify-start p-2 bg-[#f4f4f5] text-black hover:bg-[#D9D9D9] w-full">
                <MessageCircleQuestion className="mr-3 h-[20px] w-[20px]" /><p className='text-md font-normal'>Help & Support</p>
            </Button>

        </>
    );
};

export default NavButtons;