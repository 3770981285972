import { Button } from "@/@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/@/components/ui/dialog";
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import { Link, PenLine, Trash } from "lucide-react";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import Spinner from "./Spinner";
import { SurveyContext } from "./SurveyContext";

const Home = () => {
  const [forms, setForms] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { user, isAuthenticated } = useAuth0();
  const { setSubmissionsCount, submissionsCount, link } = useContext(SurveyContext); // Use the context
  const [maxResponses, setMaxResponses] = useState(20); // Default to Free Plan

  const [isEditing, setIsEditing] = useState(null); // Track which form is being edited
  const [newTitle, setNewTitle] = useState(""); // Store the new title
  const inputRef = useRef(null); // Create a reference to the input element

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-subscription/${user.sub}`);
        const subscription = response.data.subscription;

        const proPriceId = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_PRO_PRICE_ID : process.env.REACT_APP_DEV_PRO_PRICE_ID;
        const businessPriceId = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_BUSINESS_PRICE_ID : process.env.REACT_APP_DEV_BUSINESS_PRICE_ID;

        if (!subscription) {
          setMaxResponses(20); // No subscription
        } else if (subscription.plan.id === proPriceId) {
          setMaxResponses(200); // Pro Plan
        } else if (subscription.plan.id === businessPriceId) {
          setMaxResponses(500); // Business Plan
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setMaxResponses(20); // No subscription found
        } else {
          console.error("Error fetching subscription:", error);
        }
      }
    };

    if (user && user.sub) {
      fetchSubscription();
    }
  }, [user]);

  const sendUserInfo = useCallback(async () => {
    try {
      const userInfo = {
        user_id: user.sub,
        user_email: user.email
      };
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/user-signup`, userInfo);
    } catch (error) {
      console.error('Error sending user info:', error);
    }
  }, [user]);

  const fetchForms = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-forms/${user.sub}`);
      const reversedForms = response.data.forms.reverse();
      setForms(reversedForms);
      const totalSubmissions = reversedForms.reduce((acc, form) => acc + form.response_count, 0);
      setSubmissionsCount(totalSubmissions);
    } catch (error) {
      console.error('Error fetching forms:', error);
    } finally {
      setLoading(false);
    }
  }, [user.sub, setSubmissionsCount]);

  useEffect(() => {
    if (isAuthenticated && user && user.sub) {
      fetchForms();
      sendUserInfo();
    }
  }, [fetchForms, user, isAuthenticated, sendUserInfo]);

  const deleteForm = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/delete-form/${id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      fetchForms();
    } catch (error) {
      console.error('Error deleting form:', error);
    }
  };

  const handleNewFormClick = () => {
    navigate('/newform');
  };

  const handleFormClick = (formId) => {
    navigate('/editinterview', { state: { formId } });
  };

  const handleSampleFormClick = () => {
    navigate('/sampleform');
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(link);
    toast.success('Link copied!');
  };

  const handleEditClick = (formId, currentTitle) => {
    setIsEditing(formId);
    setNewTitle(currentTitle);
    setTimeout(() => {
      inputRef.current?.focus(); // Focus the input element
    }, 0);
  };

  const handleTitleChange = (e) => {
    setNewTitle(e.target.value);
  };

  const handleSave = async (formId, currentTitle) => {
    if (newTitle.trim() === "" || newTitle === currentTitle) {
      setIsEditing(null);
      return;
    }

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-form/${formId}`);
      const formData = response.data.form;

      // Update the title in the form data
      formData.title = newTitle;

      // Send the updated form data in the PUT request
      await axios.put(`${process.env.REACT_APP_API_BASE_URL}/update-form/${formId}`, {
        title: formData.title,
        interviewName: formData.interviewName,
        interviewInfo: formData.interviewInfo,
        interviewInstructions: formData.interviewInstructions,
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      // Update the form title in the state
      setForms((prevForms) =>
        prevForms.map((form) =>
          form.id === formId ? { ...form, title: newTitle } : form
        )
      );

      // Exit editing mode
      setIsEditing(null);
    } catch (error) {
      console.error('Error updating form title:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
      }
    }
  };

  if (loading) {
    return <div className="flex justify-center items-center h-[600px]">
      <Spinner />
    </div>;
  }

  return (
    <div className="p-4">
      <div className="flex justify-between border-b-[1px] border-RGB(237,237,236) mb-8 pb-2">
        <h1 className="text-2xl font-bold mb-2">Home</h1>
        <ToastContainer
          position="bottom-right"
          autoClose={1000}
          limit={1}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          transition={Bounce}
          theme="colored"
        />
        <div className="flex justify-between mb-2">
          <button
            onClick={maxResponses > 0 ? handleNewFormClick : () => navigate('/settings')}
            disabled={submissionsCount >= maxResponses}
            className="bg-[#0C003F] disabled:bg-opacity-50 text-white py-2 px-4 rounded-md text-sm"
          >
            {maxResponses >= submissionsCount ? '+ New Project' : 'Upgrade to create new project'}
          </button>
          {console.log(maxResponses, "maxResponses")}
          {console.log(submissionsCount, "submissionsCount")}
        </div>
      </div>
      <div>
        {forms.length === 0 ? (
          <div className="hover:cursor-pointer mb-2 p-2 px-4 rounded-lg shadow-sm hover:bg-[#ededec] group">
            <div onClick={handleSampleFormClick} className="flex justify-between items-center">
              <div className="w-[1000px]">
                <h2 className="text-sm font-semibold">Sample Interview</h2>
                <p className="text-gray-600 text-sm">1 Response</p>
              </div>
              <div className="flex space-x-1 opacity-0 group-hover:opacity-100 transition-opacity duration-100">
                <button className="text-[#9e9e9b] hover:text-gray-800 flex items-center justify-center">
                  <span className="scale-75">
                    <PenLine />
                  </span>
                </button>
                <button className="text-[#9e9e9b] hover:text-gray-800 flex items-center justify-center">
                  <span className="scale-75">
                    <Link />
                  </span>
                </button>
                <button className="text-[#9e9e9b] hover:text-gray-800 flex items-center justify-center">
                  <span className="scale-75">
                    <Trash />
                  </span>
                </button>
                <button className="text-[#9e9e9b] hover:text-gray-800 flex items-center justify-center">
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="hover:cursor-pointer mb-2 p-2 px-4 rounded-lg shadow-sm hover:bg-[#ededec] group">
              <div onClick={handleSampleFormClick} className="flex justify-between items-center">
                <div className="w-[1000px]">
                  <h2 className="text-sm font-semibold">Sample Interview</h2>
                  <p className="text-gray-600 text-sm">1 Response</p>
                </div>
                <div className="flex space-x-1 opacity-0 group-hover:opacity-100 transition-opacity duration-100">
                  <button className="text-[#9e9e9b] hover:text-gray-800 flex items-center justify-center">
                    <span className="scale-75">
                      <PenLine />
                    </span>
                  </button>
                  <button className="text-[#9e9e9b] hover:text-gray-800 flex items-center justify-center">
                    <span className="scale-75">
                      <Link />
                    </span>
                  </button>
                  <button className="text-[#9e9e9b] hover:text-gray-800 flex items-center justify-center">
                    <span className="scale-75">
                      <Trash />
                    </span>
                  </button>
                  <button className="text-[#9e9e9b] hover:text-gray-800 flex items-center justify-center">
                  </button>
                </div>
              </div>
            </div>
            {forms.map((form, index) => (
              <div key={index} className="hover:cursor-pointer mb-2 p-2 px-4 rounded-lg shadow-sm hover:bg-[#ededec] group">
                <div className="flex justify-between items-center">
                  <div className="w-[1000px]">
                    {isEditing === form.id ? (
                      <div >
                        <input
                          type="text"
                          value={newTitle}
                          onChange={handleTitleChange}
                          onBlur={() => handleSave(form.id)}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              handleSave(form.id);
                            }
                          }}
                          className="w-30 text-sm font-semibold bg-white"
                          ref={inputRef} // Attach the reference to the input element
                        />
                        <p className="text-gray-600 text-sm ">
                          {form.response_count} {form.response_count === 1 ? 'response' : 'responses'}
                        </p>
                      </div>
                    ) : (
                      <div onClick={() => handleFormClick(form.id)}>
                        <h2 className="text-sm font-semibold">{form.title}</h2>
                        <p className="text-gray-600 text-sm">
                          {form.response_count} {form.response_count === 1 ? 'response' : 'responses'}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="flex space-x-1 opacity-0 group-hover:opacity-100 transition-opacity duration-100">
                    <button className="text-[#9e9e9b] hover:text-gray-800 flex items-center justify-center" onClick={() => handleEditClick(form.id, form.title)}>
                      <span className="scale-75">
                        <PenLine />
                      </span>
                    </button>
                    <button className="text-[#9e9e9b] hover:text-gray-800 flex items-center justify-center">
                      <span className="scale-75">
                        <Link onClick={() => { handleCopyClick(); }} />
                      </span>
                    </button>
                    <Dialog>
                      <DialogTrigger asChild>
                        <button className="text-[#9e9e9b] hover:text-gray-800 flex items-center justify-center">
                          <span className="scale-75">
                            <Trash />
                          </span>
                        </button>
                      </DialogTrigger>
                      <DialogContent className="sm:max-w-[425px]">
                        <DialogHeader>
                          <DialogTitle>Delete Confirmation</DialogTitle>
                          <DialogDescription>
                            Are you sure you want to delete this interview? ({form.title}) Once deleted, this interview will not be recoverable as well as its responses.
                          </DialogDescription>
                        </DialogHeader>
                        <DialogFooter>
                          <DialogTrigger asChild>
                            <Button type="submit" className="bg-red-500 hover:bg-red-700" onClick={() => {
                              deleteForm(form.id);
                            }}
                            >Delete Interview</Button>
                          </DialogTrigger>
                        </DialogFooter>
                      </DialogContent>
                    </Dialog>
                    <button className="text-[#9e9e9b] hover:text-gray-800 flex items-center justify-center">
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;