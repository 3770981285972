import React from 'react';

const EditTabContent = ({ interview, handleNameChange, handleInfoChange, handleInstructionsChange }) => {
  return (
    <div>
      <div className="mb-4">
        <div className="flex items-center">
          <h2 className="text-lg font-semibold">Interview Name</h2>
          <span className="text-red-500 font-bold text-xl ml-1">*</span>
        </div>
        <p className="text-gray-600 text-sm mb-2">Please provide a title for this interview (This is the title your participants will see).</p>
        <input
          className="border rounded-lg p-2"
          value={interview.interviewName}
          onChange={handleNameChange}
        ></input>
      </div>
      <div className="mb-4">
        <div className="flex items-center">
          <h2 className="text-lg font-semibold">Interview Information</h2>
          <span className="text-red-500 font-bold text-xl ml-1">*</span>
        </div>
        <p className="text-gray-600 text-sm mb-2">Please provide information on what this interview will be about (This will be shown to the participant to provide them with context on the interview).</p>
        <textarea
          className="w-full h-32 border rounded-lg p-2"
          value={interview.interviewInfo}
          onChange={handleInfoChange}
        ></textarea>
      </div>
      <div className="mb-4">
        <div className="flex items-center">
          <h2 className="text-lg font-semibold">Configure Your AI Interviewer</h2>
          <span className="text-red-500 font-bold text-xl ml-1">*</span>
        </div>
        <p className="text-gray-600 text-sm mb-2">Please provide a prompt to configure your AI interviewer. This is where you will describe how the AI interviewer should behave and what questions it should ask during the interview.</p>
        <textarea
          className="w-full h-32 border rounded-lg p-2"
          value={interview.interviewInstructions}
          onChange={handleInstructionsChange}
        ></textarea>
      </div>
    </div>
  );
};

export default EditTabContent;