import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";



const SurveyIntro = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();
    const [form, setForm] = useState(location.state?.form || null);
    const [isLoading, setIsLoading] = useState(!form);


    useEffect(() => {
        if (!form) {
            const fetchForm = async () => {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-form/${id}`);
                    setForm(response.data.form);
                } catch (error) {
                    console.error('Error fetching form:', error);
                }
            };
            fetchForm();
        } else {
            setIsLoading(false);
        }
    }, [form, id]);

    const handleButtonClick = () => {
        navigate(`/call/${form.id}`, { state: { form } });
    };

    if (isLoading) {
        return;
    }

    return (
        <div className=" flex flex-col justify-center items-center">

            <div className="max-w-2xl w-full p-8">
                <h2 className="text-[30px] font-bold mb-4 font-sans">{form?.interviewName !== "" ? form?.interviewName : "Your title here"}</h2>
                <p className="mb-4 text-[20px]">
                    {form.interviewInfo !== "" ? form.interviewInfo : "Your interview info here"}
                </p>
                {/* <p className="mb-8 text-[20px]">
                    Please answer honestly and to the best of your ability.
                </p> */}
                <div className="flex justify-start">
                    <button onClick={handleButtonClick} className="py-2 px-4  text-white font-semibold rounded-md bg-[#0C003F]  hover:bg-[#0c003fc8]">
                        Get Started
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SurveyIntro;
