import { Link } from 'react-router-dom';
import Upgrade from "../upgrade.jpg";


const UpgradePlan = () => {
  return (
    <div className="flex justify-between items-center bg-white p-6 rounded-lg  w-full max-w-5xl mx-auto">
      <div className="w-1/2 flex items-center justify-center">
        <img src={Upgrade} alt="upgrade" ></img>
      </div>
      <div className="w-1/2 flex flex-col justify-center ml-6 ">
        <h2 className="text-2xl font-bold text-gray-800 mb-4">Upgrade Plan</h2>
        <p className="text-gray-600 mb-4">
          Interview without limits by upgrading your plan.
        </p>
        <ul className="space-y-2 mb-4">
          <li className="flex items-center space-x-2">
            <span className="text-green-500">✔️</span>
            <span>More responses</span>
          </li>
          <li className="flex items-center space-x-2">
            <span className="text-green-500">✔️</span>
            <span>More users</span>
          </li>
          <li className="flex items-center space-x-2">
            <span className="text-green-500">✔️</span>
            <span>More insights</span>
          </li>
        </ul>
        <div className="flex items-center mb-2">
          <div className="flex items-center bg-[#0c003f2b] border border-[#0C003F] text-[0C003F] px-4 py-2 rounded-l-lg">
            <span>Pro Plan</span>

          </div>
          <div className="border border-[#0C003F] text-[#0C003F] px-4 py-2 rounded-r-lg">
            $99 / month
          </div>
        </div>
        <div className="flex items-center mb-4">
          <div className="flex items-center bg-purple-50 border border-purple-500 text-purple-700 px-4 py-2 rounded-l-lg">
            <span>Business Plan</span>

          </div>
          <div className="border border-purple-500 text-purple-700 px-4 py-2 rounded-r-lg">
            $249 / month
          </div>
        </div>

        <Link to="/settings" className="bg-[#0C003F] text-white px-6 py-3 rounded-lg font-semibold hover:bg-gray-900 inline-block text-center">
          Upgrade Now
        </Link>
      </div>
    </div>
  );
};

export default UpgradePlan;
