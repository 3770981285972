import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/@/components/ui/table";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger, DialogDescription } from "@/@/components/ui/dialog";
import { Input } from "@/@/components/ui/input";
import { format } from 'date-fns';
import { Button } from "@/@/components/ui/button";
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "@/@/components/ui/accordion";
import { BarChart2, FileType2, PlayIcon } from "lucide-react";
import AnalysisTab from './AnalysisTab';

const SubmissionsTabContent = ({ interview }) => {
    const [submissions, setSubmissions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedSubmission, setSelectedSubmission] = useState(null);
    const [activeTab, setActiveTab] = useState('results');

    const fetchSubmissions = useCallback(async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-responses/${interview.id}`);
            const submissions = response.data.responses;
            if (submissions && submissions.length > 0) {
                const reversedSubmissions = submissions.reverse();
                setSubmissions(reversedSubmissions);
            } else {
                console.error('No submissions found in the response');
            }
        } catch (error) {
            console.error('Error fetching submissions:', error);
        } finally {
            setLoading(false);
        }
    }, [interview.id]);

    useEffect(() => {
        fetchSubmissions();
    }, [fetchSubmissions]);



    const filteredSubmissions = submissions.filter(submission => {
        const firstName = submission.firstName || '';
        const lastName = submission.lastName || '';
        const email = submission.email || '';
        const phoneNumber = submission.phoneNumber || '';
        const interviewSummary = submission.interview_summary || '';
        const interviewTranscript = submission.interview_transcript || '';
        const interviewRecording = submission.interview_recording || '';
        const query = searchQuery.toLowerCase();
        return (
            firstName.toLowerCase().includes(query) ||
            lastName.toLowerCase().includes(query) ||
            email.toLowerCase().includes(query) ||
            phoneNumber.toLowerCase().includes(query) ||
            interviewSummary.toLowerCase().includes(query) ||
            interviewTranscript.toLowerCase().includes(query) ||
            interviewRecording.toLowerCase().includes(query)
        );
    });

    const isValidUrl = (string) => {
        try {
            new URL(string);
            return true;
        } catch (_) {
            return false;
        }
    };

    const formatTranscript = (transcript) => {
        const lines = transcript.split(/(?=AI:|User:)/);
        return lines.map(line => {
            const trimmedLine = line.trim();
            return {
                text: trimmedLine,
                speaker: trimmedLine.startsWith('AI:') ? 'AI' : 'User'
            };
        });
    };

    const downloadCSV = () => {
        const csvData = filteredSubmissions.map(submission => ({
            "Submitted At": format(new Date(submission.submitted_at), 'MMM d, h:mma'),
            "First Name": submission.firstName,
            "Last Name": submission.lastName,
            "Phone Number": submission.phoneNumber,
            "Email": submission.email,
            "Interview Summary": submission.interview_summary,
            "Interview Recording": submission.interview_recording,
            "Interview Transcript": submission.interview_transcript,
        }));

        const csv = Papa.unparse(csvData);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'submissions.csv');
    };

    return (
        <div className="p-2">
            {/* <div className="flex justify-center border-b border-gray-200 mb-2 text-sm">
                <button
                    className={`px-4 py-2 -mb-[1px] ${activeTab === 'results'
                        ? 'border-b-2 border-[#0C003F] text-[#0C003F] font-medium'
                        : 'text-gray-500 hover:text-gray-700'
                        }`}
                    onClick={() => setActiveTab('results')}
                >
                    Results
                </button>
                <button
                    className={`px-4 py-2 -mb-[1px] ${activeTab === 'analysis'
                        ? 'border-b-2 border-[#0C003F] text-[#0C003F] font-medium'
                        : 'text-gray-500 hover:text-gray-700'
                        }`}
                    onClick={() => setActiveTab('analysis')}
                >
                    Analysis
                </button>
                <button
                    className={`px-4 py-2 -mb-[1px] ${activeTab === 'search'
                        ? 'border-b-2 border-[#0C003F] text-[#0C003F] font-medium'
                        : 'text-gray-500 hover:text-gray-700'
                        }`}
                    onClick={() => setActiveTab('search')}
                >
                    Search
                </button>
                <button
                    className={`px-4 py-2 -mb-[1px] ${activeTab === 'insights'
                        ? 'border-b-2 border-[#0C003F] text-[#0C003F] font-medium'
                        : 'text-gray-500 hover:text-gray-700'
                        }`}
                    onClick={() => setActiveTab('insights')}
                >
                    Insights
                </button>
            </div> */}

            {activeTab === 'results' && (
                <>
                    <div className="flex justify-end pb-2">
                        <div className="flex mb-4">
                            {filteredSubmissions.length > 0 && <Button className="bg-[#0C003F]  hover:bg-[#0c003fc8] mr-4" onClick={downloadCSV}>Download as CSV</Button>}
                            <Input
                                type="text"
                                placeholder="Search..."
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </div>
                    </div>
                    {loading ? (
                        <div>Loading...</div>
                    ) : filteredSubmissions.length === 0 ? (
                        <div className="flex flex-col items-center justify-center h-full py-10">
                            <svg
                                className="w-16 h-16 mb-4 text-gray-400"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M9 12h6m-6 4h6m2 4H7a2 2 0 01-2-2V6a2 2 0 012-2h7l5 5v11a2 2 0 01-2 2z"
                                ></path>
                            </svg>
                            <h2 className="text-xl font-semibold text-gray-700">No Responses Found</h2>
                            <p className="text-gray-500">Share your link to start receiving responses</p>
                        </div>
                    ) : (
                        <Table>
                            <TableCaption>Responses</TableCaption>
                            <TableHeader>
                                <TableRow>
                                    <TableHead>Submitted At</TableHead>
                                    <TableHead>First Name</TableHead>
                                    <TableHead>Last Name</TableHead>
                                    <TableHead>Phone Number</TableHead>
                                    <TableHead>Email</TableHead>
                                    <TableHead>Response</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {filteredSubmissions.map((submission, index) => (
                                    <Dialog key={index} open={selectedSubmission === index} onOpenChange={(isOpen) => setSelectedSubmission(isOpen ? index : null)}>
                                        <DialogTrigger asChild>
                                            <TableRow className="cursor-pointer group" onClick={() => setSelectedSubmission(index)}>
                                                <TableCell className="font-medium">
                                                    {format(new Date(submission.submitted_at), 'MMM d, h:mma')}
                                                </TableCell>
                                                <TableCell>{submission.firstName}</TableCell>
                                                <TableCell>{submission.lastName}</TableCell>
                                                <TableCell>{submission.phoneNumber}</TableCell>
                                                <TableCell>{submission.email}</TableCell>
                                                <TableCell className="text-right">
                                                    <Button className="opacity-0 group-hover:opacity-100 transition-opacity text-white bg-[#0C003F]  hover:bg-[#0c003fc8]">View Response →</Button>
                                                </TableCell>
                                            </TableRow>
                                        </DialogTrigger>
                                        <DialogContent className="max-w-[700px] h-[500px] ">
                                            <DialogHeader>
                                                <DialogTitle>Interview Response Details</DialogTitle>
                                                <DialogDescription>
                                                    <Accordion type="single" collapsible className="w-full" defaultValue="item-1">
                                                        <AccordionItem value="item-1">
                                                            <AccordionTrigger>
                                                                <div className="flex">
                                                                    <BarChart2 className="mr-2 mt-[-4px] text-black" />
                                                                    <h2 className="text-black"> Summary</h2>
                                                                </div>
                                                            </AccordionTrigger>
                                                            <AccordionContent>
                                                                <div className="max-h-40 overflow-y-auto">
                                                                    {submission?.interview_summary || 'No summary provided'}
                                                                </div>
                                                            </AccordionContent>
                                                        </AccordionItem>
                                                        <AccordionItem value="item-2">
                                                            <AccordionTrigger>
                                                                <div className="flex">
                                                                    <PlayIcon className="mr-2 fill-black text-black mt-[-2px]" />
                                                                    <h2 className="text-black"> Recording</h2>
                                                                </div>
                                                            </AccordionTrigger>
                                                            <AccordionContent>
                                                                {isValidUrl(submission?.interview_recording) ? (
                                                                    <a className="underline text-blue-500" href={submission.interview_recording} target="_blank" rel="noopener noreferrer">
                                                                        Recording URL
                                                                    </a>
                                                                ) : (
                                                                    'No recording provided'
                                                                )}
                                                            </AccordionContent>
                                                        </AccordionItem>
                                                        <AccordionItem value="item-3">
                                                            <AccordionTrigger>
                                                                <div className="flex">
                                                                    <FileType2 className="mr-2 text-black mt-[-4px]" />
                                                                    <h2 className="text-black"> Transcript</h2>
                                                                </div>
                                                            </AccordionTrigger>
                                                            <AccordionContent>
                                                                <div className="max-h-64 overflow-y-auto whitespace-pre-wrap">
                                                                    {submission?.interview_transcript ? formatTranscript(submission.interview_transcript).map((line, index) => (
                                                                        <div key={index} className="mb-2">
                                                                            <span className={line.speaker === 'AI' ? 'text-green-500' : 'text-blue-500'}>
                                                                                {line.speaker}:
                                                                            </span> {line.text.replace(/^(AI:|User:)/, '')}
                                                                        </div>
                                                                    )) : 'No transcript provided'}
                                                                </div>
                                                            </AccordionContent>
                                                        </AccordionItem>
                                                    </Accordion>
                                                </DialogDescription>
                                            </DialogHeader>
                                        </DialogContent>
                                    </Dialog>
                                ))}
                            </TableBody>
                        </Table>
                    )}
                </>
            )}

            {activeTab === 'analysis' && (
                <AnalysisTab interview={interview} />
            )}

            {activeTab === 'search' && (
                <div className="flex flex-col items-center justify-center h-full py-10">
                    <h2 className="text-xl font-semibold text-gray-700">Search Coming Soon</h2>
                    <p className="text-gray-500">This feature is under development</p>
                </div>
            )}

            {activeTab === 'insights' && (
                <div className="flex flex-col items-center justify-center h-full py-10">
                    <h2 className="text-xl font-semibold text-gray-700">Insights Coming Soon</h2>
                    <p className="text-gray-500">This feature is under development</p>
                </div>
            )}
        </div>
    );
};

export default SubmissionsTabContent;