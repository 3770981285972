import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import SideNavbar from './reactcomponents/SideNavbar';
import NotFound from './reactcomponents/NotFound';
import ProtectedRoute from './reactcomponents/ProtectedRoute';
import Homepage from './reactcomponents/Homepage';
import NewInterview from './reactcomponents/NewInterview';
import ContactForm from './reactcomponents/ContactForm';
import ProLogo from './reactcomponents/AptitudeLogo';
import SurveyIntro from './reactcomponents/SurveyIntro';
import { SurveyContext } from './reactcomponents/SurveyContext';
import EditInterview from './reactcomponents/EditInterview';
import Callpage from './reactcomponents/CallPage';
import { useContext } from 'react';
import SettingsNavbar from './reactcomponents/SettingsNavbar';
import Plans from './reactcomponents/Plans';
import SampleInterview from './reactcomponents/SampleInterview';

function App() {
  const { isCollapsed } = useContext(SurveyContext); // Consume the context


  return (
    <>
      <Router>
        <Routes>
          {/* <Route path="/login" element={<LoginPage />} /> */}
          <Route path="/" element={<Navigate to="/home" replace />
          }
          />
          <Route path="/home" element={
            <ProtectedRoute>
              <div className='flex'>
                <div className={`py-[15px] pl-[28px] w-fit pr-12 h-screen flex flex-col border-r-2 ${isCollapsed ? 'bg-white' : 'bg-[#f4f4f5]'} ${isCollapsed ? 'border-white' : 'border-[#f4f4f5]'} `}>
                  <SideNavbar />
                </div>
                <div className="flex flex-col w-full h-full overflow-y-auto p-20">
                  <Homepage />
                </div>
              </div>
            </ProtectedRoute>
          } />
          <Route path="/newform" element={
            <ProtectedRoute>
              <div className='flex'>
                <div className={`py-[15px] pl-[28px] w-fit pr-12 h-screen flex flex-col border-r-2 ${isCollapsed ? 'bg-white' : 'bg-[#f4f4f5]'} ${isCollapsed ? 'border-white' : 'border-[#f4f4f5]'} `}>
                  <SideNavbar />
                </div>
                <div className=" pt-[35px] px-[50px] flex flex-col w-full h-full overflow-y-auto p-6">
                  <NewInterview />
                </div>
              </div>
            </ProtectedRoute>
          } />
          <Route path="/editinterview" element={
            <ProtectedRoute>
              <div className='flex'>
                <div className={`py-[15px] pl-[28px] w-fit pr-12 h-screen flex flex-col border-r-2 ${isCollapsed ? 'bg-white' : 'bg-[#f4f4f5]'} ${isCollapsed ? 'border-white' : 'border-[#f4f4f5]'} `}>
                  <SideNavbar />
                </div>
                <div className=" pt-[35px] px-[50px] flex flex-col w-full h-full overflow-y-auto p-6">
                  <EditInterview />
                </div>
              </div>
            </ProtectedRoute>
          } />
          <Route path="/sampleform" element={
            <ProtectedRoute>
              <div className='flex'>
                <div className={`py-[15px] pl-[28px] w-fit pr-12 h-screen flex flex-col border-r-2 ${isCollapsed ? 'bg-white' : 'bg-[#f4f4f5]'} ${isCollapsed ? 'border-white' : 'border-[#f4f4f5]'} `}>
                  <SideNavbar />
                </div>
                <div className=" pt-[35px] px-[50px] flex flex-col w-full h-full overflow-y-auto p-6">
                  <SampleInterview />
                </div>
              </div>
            </ProtectedRoute>
          } />
          <Route path="/settings" element={
            <ProtectedRoute>
              <div className='flex'>
                <div className={`py-[35px] pl-[28px] w-fit pr-12 h-screen flex flex-col border-r-2 ${isCollapsed ? 'bg-white' : 'bg-[#f4f4f5]'} ${isCollapsed ? 'border-white' : 'border-[#f4f4f5]'} `}>
                  <SettingsNavbar />
                </div>
                <div className=" pt-[35px] px-[50px] flex flex-col w-full h-full overflow-y-auto p-6">
                  <Plans />
                </div>
              </div>
            </ProtectedRoute>
          } />
          {/* <Route path="/sampleform" element={ } /> */}
          <Route path="/:id" element={
            <>
              <div className="py-[45px] ml-[28px] w-fit pr-10 h-screen flex flex-col fixed">
                <div className='h-screen flex flex-col justify-between'>
                  <div>
                    <ProLogo />
                  </div>
                </div>
              </div >
              <div className="flex flex-col pt-40 ">
                <ContactForm />
              </div>
            </>
          } />
          <Route path="/survey-intro/:id" element={
            <>
              <div className="py-[45px] ml-[28px] w-fit pr-10 h-screen flex flex-col fixed">
                <div className='h-screen flex flex-col justify-between'>
                  <div>
                    <ProLogo />
                  </div>
                </div>
              </div >
              <div className="flex flex-col pt-40 ">
                <SurveyIntro />
              </div>
            </>
          } />
          <Route path="/call/:id" element={
            <>
              <div className="py-[45px] ml-[28px] w-fit pr-10 h-screen flex flex-col fixed">
                <div className='h-screen flex flex-col justify-between'>
                  <div>
                    <ProLogo />
                  </div>
                </div>
              </div >
              <div className="flex flex-col ">
                <Callpage />
              </div>
            </>

          } />
          <Route path="/contactform/surveyintro/call/confirmation" element={
            <ProtectedRoute>

              <div className="py-[45px] ml-[28px] w-fit pr-10 h-screen flex flex-col fixed">
                <div className='h-screen flex flex-col justify-between'>
                  <div>
                    <ProLogo />
                  </div>
                </div>
              </div >
              <div className="flex flex-col ">
                <div className='flex flex-col items-center justify-center h-screen'>
                  <h1 className='text-2xl font-bold'>Thank you for your submission!</h1>
                </div>
              </div>

            </ProtectedRoute>
          } />
          <Route path="/success" element={
            <ProtectedRoute>

              <div className="py-[45px] ml-[28px] w-fit pr-10 h-screen flex flex-col fixed">
                <div className='h-screen flex flex-col justify-between'>
                  <div>
                    <ProLogo />
                  </div>
                </div>
              </div >
              <div className="flex flex-col ">
                <div className='flex flex-col items-center justify-center h-screen'>
                  <h1 className='text-2xl font-bold'>Checkout Success!</h1>
                </div>
              </div>

            </ProtectedRoute>
          } />
          <Route path="/cancel" element={
            <ProtectedRoute>

              <div className="py-[45px] ml-[28px] w-fit pr-10 h-screen flex flex-col fixed">
                <div className='h-screen flex flex-col justify-between'>
                  <div>
                    <ProLogo />
                  </div>
                </div>
              </div >
              <div className="flex flex-col ">
                <div className='flex flex-col items-center justify-center h-screen'>
                  <h1 className='text-2xl font-bold'>Checkout cancelled!</h1>
                </div>
              </div>

            </ProtectedRoute>
          } />
          {/* <Route path="/logout" element={
            <div>YOUVE BEEN LOGGED OUT</div>
          } /> */}
          <Route path="*" element={<NotFound />} />
        </Routes>

      </Router >
    </>
  );
}

export default App;;