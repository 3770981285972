import React, { useState, useEffect, useRef } from 'react';
import { Button } from "@/@/components/ui/button";
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "@/@/components/ui/accordion";
import { BarChart2, FileType2, PlayIcon } from "lucide-react";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger, DialogDescription } from "@/@/components/ui/dialog";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);


const AnalysisTab = ({ interview }) => {
    const [analysisData, setAnalysisData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [lastRunTime, setLastRunTime] = useState(null);
    const [selectedThemes, setSelectedThemes] = useState({});
    const [responses, setResponses] = useState([]);
    const [selectedResponse, setSelectedResponse] = useState(null);
    const chartRefs = useRef([]);

    const colors = [
        '#FFC107', // Bright Yellow
        '#2196F3', // Vivid Blue
        '#FF5722', // Bright Coral
        '#009688', // Teal
        '#FFEB3B', // Sunshine Yellow
        '#4CAF50', // Strong Green
        '#FF6F61'  // Vibrant Peach
    ];

    useEffect(() => {
        const fetchAnalysisData = async () => {
            setLoading(true);
            setError(null);
            try {
                const url = `${process.env.REACT_APP_API_BASE_URL}/get-form/${interview.id}`;

                const response = await axios.get(url);
                if (response.data && response.data.form.analysis_json) {
                    setAnalysisData(JSON.parse(response.data.form.analysis_json));
                } else {
                    setError('No analysis data available. Please run a report to analyze your interview transcripts.');
                }
            } catch (err) {
                console.error('Fetch analysis error:', err.response);
                setError(err.response?.data?.error || 'Failed to fetch analysis data. Please run a report to analyze your interview transcripts.');
            } finally {
                setLoading(false);
            }
        };

        const fetchResponses = async () => {
            try {
                const url = `${process.env.REACT_APP_API_BASE_URL}/get-responses/${interview.id}`;
                const response = await axios.get(url);
                setResponses(response.data.responses);
            } catch (err) {
                console.error('Fetch responses error:', err.response);
            }
        };

        fetchAnalysisData();
        fetchResponses();
    }, [interview.id]);

    const runAnalysis = async () => {
        setLoading(true);
        setError(null);
        try {
            const url = `${process.env.REACT_APP_API_BASE_URL}/analyze-transcripts/${interview.id}`;

            const response = await axios.post(url);
            setAnalysisData(response.data);
            setLastRunTime(new Date().toLocaleString([], { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true }));
        } catch (err) {
            console.error('Analysis error:', err.response);

            if (err.response?.data?.error === 'No transcripts available for analysis') {
                setError(
                    <div className="text-center">
                        <p className="text-red-500 mb-2">No submission transcripts are available yet.</p>
                        <p className="text-sm text-gray-600">
                            Please wait for submissions to be completed and transcribed before running the analysis.
                        </p>
                    </div>
                );
            } else if (err.response?.data?.error === 'No responses found for this form') {
                setError(
                    <div className="text-center">
                        <p className="text-red-500 mb-2">No responses have been recorded.</p>
                        <p className="text-sm text-gray-600">
                            Share your interview form with participants to start collecting responses.
                        </p>
                    </div>
                );
            } else {
                setError(err.response?.data?.error || 'Failed to run analysis');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleThemeClick = (questionIndex, theme) => {
        setSelectedThemes(prev => ({
            ...prev,
            [questionIndex]: theme
        }));
    };

    const handleCloseQuotes = (questionIndex) => {
        setSelectedThemes(prev => {
            const newThemes = { ...prev };
            delete newThemes[questionIndex];
            return newThemes;
        });
    };

    const handleViewTranscript = (responseId) => {
        const response = responses.find(res => res.id === responseId);
        setSelectedResponse(response);
    };

    const isValidUrl = (string) => {
        try {
            new URL(string);
            return true;
        } catch (_) {
            return false;
        }
    };

    const formatTranscript = (transcript) => {
        let parsedTranscript;
        try {
            parsedTranscript = JSON.parse(`"${transcript}"`);
        } catch (e) {
            // If parsing fails, assume the transcript is already formatted correctly
            parsedTranscript = transcript;
        }

        const lines = parsedTranscript.split('\n');
        return lines.map(line => {
            const trimmedLine = line.trim();
            const speaker = trimmedLine.startsWith('Interviewer:') ? 'Interviewer' : 'Customer';
            const text = trimmedLine.replace(/^(Interviewer:|Customer:)/, '').trim();
            return {
                text,
                speaker
            };
        });
    };



    const renderChart = (question, questionIndex, chartRef) => {
        const labels = question.themes.map(theme => theme.theme);
        const data = {
            labels,
            datasets: [
                {
                    label: 'Customer Quotes Count',
                    data: question.themes.map(theme => theme.customer_quotes.length),
                    backgroundColor: colors,
                },
            ],
        };

        const options = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false,
                    position: 'top',
                },
                title: {
                    display: true,
                    text: question.moderation_question,
                },
            },
            onClick: (event) => {
                const chart = chartRef.current;
                if (!chart) return;

                const elements = chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, false);
                if (elements.length === 0) return;

                const { index } = elements[0];
                const theme = labels[index];
                handleThemeClick(questionIndex, theme);
            },
            onHover: (event, chartElement) => {
                const chart = chartRef.current;
                if (chartElement.length) {
                    chart.canvas.style.cursor = 'pointer';
                } else {
                    chart.canvas.style.cursor = 'default';
                }
            }
        };

        return <Bar ref={chartRef} data={data} options={options} width={400} height={300} />;
    };

    return (
        <div className="fixed h-[calc(100vh-180px)] overflow-hidden scrollbar-none pr-6 pb-6" style={{ width: '-webkit-fill-available' }}>
            <div className="h-full overflow-y-auto scrollbar-none">
                <div className="p-4">
                    {analysisData ? (
                        <>
                            <div className="flex justify-between items-center mb-6">
                                <h2 className="text-lg font-semibold">Analysis Report</h2>
                                <div className="flex items-center gap-2">
                                    {lastRunTime && (
                                        <span className="text-sm text-gray-500">
                                            Last Run {lastRunTime}
                                        </span>
                                    )}
                                    <Button
                                        className="bg-[#0C003F] hover:bg-[#0c003fc8]"
                                        onClick={runAnalysis}
                                        disabled={loading}
                                    >
                                        {loading ? 'Running...' : 'Run Report'}
                                    </Button>
                                </div>
                            </div>

                            {error && (
                                <div className="bg-white rounded-lg p-6 border border-gray-200 mb-4">
                                    {error}
                                </div>
                            )}

                            <div className="flex items-center justify-center">
                                <div className="w-full">
                                    {analysisData.moderation_question_insights.map((question, questionIndex) => (
                                        <div key={questionIndex} className="mb-8">
                                            <div className="flex gap-6">
                                                <div className={`transition-all duration-300 ${selectedThemes[questionIndex] ? 'w-1/2' : 'w-full'
                                                    }`}>
                                                    <div className="bg-white rounded-lg p-6 border border-gray-200">
                                                        <h3 className="font-medium mb-2">
                                                            {question.moderation_question}
                                                        </h3>
                                                        <p className="text-sm text-gray-600 mb-4">
                                                            {question.key_insight}
                                                        </p>
                                                        <div className="space-y-4">
                                                            {renderChart(question, questionIndex, chartRefs.current[questionIndex] = chartRefs.current[questionIndex] || React.createRef())}
                                                        </div>
                                                    </div>
                                                </div>

                                                {selectedThemes[questionIndex] && (
                                                    <div className="w-1/2">
                                                        <div className="bg-white rounded-lg p-6 border border-gray-200 max-h-[478px] overflow-y-auto scrollbar-none">
                                                            <div className="flex justify-between mb-4">
                                                                <h3 className="font-medium ">Customer Quotes</h3>
                                                                <button
                                                                    className=" text-gray-500 hover:text-gray-700 font-bold"
                                                                    onClick={() => handleCloseQuotes(questionIndex)}
                                                                >
                                                                    &times;
                                                                </button>
                                                            </div>
                                                            <div className="space-y-4">
                                                                {question.themes.flatMap(theme =>
                                                                    theme.customer_quotes.map(quote => ({
                                                                        ...quote,
                                                                        theme: theme.theme
                                                                    }))
                                                                ).filter(quote => quote.theme === selectedThemes[questionIndex]).map((quote, idx) => (
                                                                    <div key={idx} className="bg-white rounded-lg border border-gray-200 p-4">
                                                                        <div className="mb-2">
                                                                            <span className="font-medium">
                                                                                {`${quote.first_name} ${quote.last_name}`}
                                                                            </span>
                                                                        </div>
                                                                        <p className="text-gray-600 mb-3">
                                                                            {quote.customer_quote}
                                                                        </p>
                                                                        <div className="flex justify-between items-center">
                                                                            <span className="text-white text-sm px-3 py-1 rounded" style={{ backgroundColor: colors[question.themes.findIndex(t => t.theme === quote.theme) % colors.length] }}>
                                                                                {quote.theme}
                                                                            </span>
                                                                            <Dialog >
                                                                                <DialogTrigger asChild>
                                                                                    <button onClick={() => handleViewTranscript(quote.response_id)} className="text-blue-600 text-sm hover:underline">
                                                                                        View transcript
                                                                                    </button>
                                                                                </DialogTrigger>
                                                                                <DialogContent className="max-w-[700px] h-[500px] ">
                                                                                    <DialogHeader>
                                                                                        <DialogTitle>Interview Response Details: {selectedResponse?.firstName} {selectedResponse?.lastName} </DialogTitle>
                                                                                        <DialogDescription>
                                                                                            {selectedResponse ? (
                                                                                                <Accordion type="single" collapsible className="w-full" defaultValue="item-3">
                                                                                                    <AccordionItem value="item-1">
                                                                                                        <AccordionTrigger>
                                                                                                            <div className="flex">
                                                                                                                <BarChart2 className="mr-2 mt-[-4px] text-black" />
                                                                                                                <h2 className="text-black"> Summary</h2>
                                                                                                            </div>
                                                                                                        </AccordionTrigger>
                                                                                                        <AccordionContent>
                                                                                                            <div className="max-h-40 overflow-y-auto">
                                                                                                                {selectedResponse?.interview_summary || 'No summary provided'}
                                                                                                            </div>
                                                                                                        </AccordionContent>
                                                                                                    </AccordionItem>
                                                                                                    <AccordionItem value="item-2">
                                                                                                        <AccordionTrigger>
                                                                                                            <div className="flex">
                                                                                                                <PlayIcon className="mr-2 fill-black text-black mt-[-2px]" />
                                                                                                                <h2 className="text-black"> Recording</h2>
                                                                                                            </div>
                                                                                                        </AccordionTrigger>
                                                                                                        <AccordionContent>
                                                                                                            {isValidUrl(selectedResponse?.interview_recording) ? (
                                                                                                                <a className="underline text-blue-500" href={selectedResponse.interview_recording} target="_blank" rel="noopener noreferrer">
                                                                                                                    Recording URL
                                                                                                                </a>
                                                                                                            ) : (
                                                                                                                'No recording provided'
                                                                                                            )}
                                                                                                        </AccordionContent>
                                                                                                    </AccordionItem>
                                                                                                    <AccordionItem value="item-3">
                                                                                                        <AccordionTrigger>
                                                                                                            <div className="flex">
                                                                                                                <FileType2 className="mr-2 text-black mt-[-4px]" />
                                                                                                                <h2 className="text-black"> Transcript</h2>
                                                                                                            </div>
                                                                                                        </AccordionTrigger>
                                                                                                        <AccordionContent>
                                                                                                            <div className="max-h-64 overflow-y-auto whitespace-pre-wrap">
                                                                                                                {selectedResponse.interview_transcript ? formatTranscript(selectedResponse.interview_transcript).map((line, index) => (
                                                                                                                    <div key={index} className="mb-2">
                                                                                                                        <span className={line.speaker === 'Interviewer' ? 'text-green-500' : 'text-blue-500'}>
                                                                                                                            {line.speaker}:
                                                                                                                        </span> {line.text}
                                                                                                                    </div>
                                                                                                                )) : 'No transcript provided'}
                                                                                                            </div>
                                                                                                        </AccordionContent>
                                                                                                    </AccordionItem>
                                                                                                </Accordion>
                                                                                            ) : null}
                                                                                        </DialogDescription>
                                                                                    </DialogHeader>
                                                                                </DialogContent>
                                                                            </Dialog>

                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="flex items-center justify-center h-full">
                            <div className="text-center text-gray-500 max-w-lg w-full">
                                <div className="bg-white rounded-lg p-8 border border-gray-200">
                                    <h3 className="text-lg font-medium mb-4">Analysis Report</h3>
                                    <p className="mb-6">Run a report to analyze your interview transcripts.</p>
                                    {error ? (
                                        <div className="mb-6">
                                            {error}
                                        </div>
                                    ) : null}
                                    <Button
                                        className="bg-[#0C003F] hover:bg-[#0c003fc8]"
                                        onClick={runAnalysis}
                                        disabled={loading}
                                    >
                                        {loading ? 'Running...' : 'Run Report'}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

        </div>
    );
};

export default AnalysisTab;